<div class="mannschaft">
    <div class="sidebar">
        <ul *ngIf="!admin">
            <li [ngClass]="sidebarStyle('liebhaber')">
                <a (click)="changeContent('liebhaber')">Liebhaber</a>
            </li>
            <li [ngClass]="sidebarStyle('zucht_m')">
                <a (click)="changeContent('zucht_m')">Zuchtmännchen</a>
            </li>
            <li [ngClass]="sidebarStyle('zucht_w')">
                <a (click)="changeContent('zucht_w')">Zuchtweibchen</a>
            </li>
        </ul>
    </div>
    <div class="animals">
        <div *ngIf="logged && !admin" id="add">
            <a (click)="adminVisible(true)">Mannschaft bearbeiten</a>
        </div>
        <div *ngIf="logged && admin" id="add">
            <a (click)="adminVisible(false)">Zurück</a>
        </div>
        <div *ngIf="liebhaber && !admin">
            <h3>Liebhaber</h3>
            <div *ngIf="data_liebhaber == undefined" style="padding: 3em 0 3em 0;">
                <div class="app-loading">
                    <div class="logo"></div>
                    <svg class="spinner" viewBox="25 25 50 50">
                        <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2"
                            stroke-miterlimit="10" />
                    </svg>
                </div>
            </div>
            <div *ngIf="data_liebhaber != undefined" class="images">
                <ul>
                    <li *ngFor="let d of data_liebhaber">
                        <a routerLink="/meerschweinchen/{{d.ID}}"><img
                                src="../../assets/images/meerschweinchen/{{d.ID}}.JPG">
                            <p>{{d.Name}}</p>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="empty" *ngIf="data_liebhaber != undefined && data_liebhaber.length == 0">
                <p style="font-style: italic;">Hier sind zurzeit keine Einträge vorhanden.</p>
            </div>
        </div>
        <div *ngIf="zucht_m && !admin">
            <h3>Zuchtmännchen</h3>
            <div *ngIf="data_zucht_m == undefined" style="padding: 3em 0 3em 0;">
                <div class="app-loading">
                    <div class="logo"></div>
                    <svg class="spinner" viewBox="25 25 50 50">
                        <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2"
                            stroke-miterlimit="10" />
                    </svg>
                </div>
            </div>
            <div class="images">
                <ul>
                    <li *ngFor="let d of data_zucht_m">
                        <a routerLink="/meerschweinchen/{{d.ID}}"><img
                                src="../../assets/images/meerschweinchen/{{d.ID}}.JPG">
                            <p>{{d.Name}}</p>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="empty" *ngIf="data_zucht_m.length == 0">
                <p style="font-style: italic;">Hier sind zurzeit keine Einträge vorhanden.</p>
            </div>
        </div>
        <div *ngIf="zucht_w && !admin">
            <h3>Zuchtweibchen</h3>
            <div *ngIf="data_zucht_w == undefined" style="padding: 3em 0 3em 0;">
                <div class="app-loading">
                    <div class="logo"></div>
                    <svg class="spinner" viewBox="25 25 50 50">
                        <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2"
                            stroke-miterlimit="10" />
                    </svg>
                </div>
            </div>
            <div class="images">
                <ul>
                    <li *ngFor="let d of data_zucht_w">
                        <a routerLink="/meerschweinchen/{{d.ID}}"><img
                                src="../../assets/images/meerschweinchen/{{d.ID}}.JPG">
                            <p>{{d.Name}}</p>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="empty" *ngIf="data_zucht_w.length == 0">
                <p style="font-style: italic;">Hier sind zurzeit keine Einträge vorhanden.</p>
            </div>
        </div>
        <div id="admin" *ngIf="admin">
            <h3>Mannschaft bearbeiten</h3>
            <table *ngIf="data_admin != undefined && data_admin.length > 0">
                <thead>
                    <tr>
                        <th>Meerschweinchen</th>
                        <th>Typ</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let d of data_admin">
                        <td>
                            <img src="../../assets/images/meerschweinchen/{{d.ID}}.JPG">
                            <p>{{d.Name}}</p>
                        </td>
                        <td>
                            <p *ngIf="!editing(d.ID) || success != -1">{{d.Typ}}</p>
                            <select *ngIf="editing(d.ID) && success == -1" [(ngModel)]="d.Typ">
                                <option>Liebhaber</option>
                                <option>Zuchttier</option>
                            </select>
                        </td>
                        <td>
                            <a *ngIf="!editing(d.ID) && !deleting(d.ID) && buttonsVisible"
                                (click)="isEditing(d.ID, true)" style="margin-right: 0.5em;">
                                <img src="../../assets/images/icons/edit.png">
                            </a>
                            <a *ngIf="!editing(d.ID) && !deleting(d.ID) && buttonsVisible"
                                (click)="isDeleting(d.ID, true)">
                                <img src="../../assets/images/icons/delete.png">
                            </a>
                            <a *ngIf="editing(d.ID) && !loading && success == -1" (click)="saveEdit(d)" type="button"
                                style="padding: 0.25em 0.5em 0.25em 0.5em; margin: 0 0.25em 0 0">Speichern</a>
                            <a *ngIf="editing(d.ID) && !loading && success == -1" (click)="isEditing(d.ID, false)"
                                type="button" style="padding: 0.25em 0.5em 0.25em 0.5em;">Abbrechen</a>
                            <a *ngIf="deleting(d.ID) && !loading && success == -1" (click)="deleteEdit(d)" type="button"
                                style="padding: 0.25em 0.5em 0.25em 0.5em; margin: 0 0.25em 0 0">Löschen</a>
                            <a *ngIf="deleting(d.ID) && !loading && success == -1" (click)="isDeleting(d.ID, false)"
                                type="button" style="padding: 0.25em 0.5em 0.25em 0.5em;">Abbrechen</a>
                            <div *ngIf="editing(d.ID) && loading" class="app-loading" style="margin: 1em 1em 1em 1em">
                                <div class="logo"></div>
                                <svg class="spinner" viewBox="25 25 50 50">
                                    <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2"
                                        stroke-miterlimit="10" />
                                </svg>
                            </div>
                            <div *ngIf="editing(d.ID) && success >= 0">
                                <p *ngIf="success == 1">Erfolgreich gespeichert!</p>
                                <p *ngIf="success == 0">Speichern fehlgeschlagen!</p>
                                <a (click)="ok(d.ID)" type="button" style="padding: 0.25em 0.5em 0.25em 0.5em;">OK</a>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>