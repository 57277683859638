<div id="nachwuchs">
    <div class="sidebar">
        <ul>
            <li [ngClass]="sidebarStyle('wuerfe2025')">
                <a (click)="changeContent('wuerfe2025')">Würfe 2025</a>
            </li>
            <li [ngClass]="sidebarStyle('wuerfe2024')">
                <a (click)="changeContent('wuerfe2024')">Würfe 2024</a>
            </li>
            <li [ngClass]="sidebarStyle('wuerfe2023')">
                <a (click)="changeContent('wuerfe2023')">Würfe 2023</a>
            </li>
            <li [ngClass]="sidebarStyle('wuerfe2022')">
                <a (click)="changeContent('wuerfe2022')">Würfe 2022</a>
            </li>
            <li [ngClass]="sidebarStyle('wuerfe2021')">
                <a (click)="changeContent('wuerfe2021')">Würfe 2021</a>
            </li>
            <li [ngClass]="sidebarStyle('wuerfe2020')">
                <a (click)="changeContent('wuerfe2020')">Würfe 2020</a>
            </li>
        </ul>
    </div>
    <div class="nachwuchs">
        <h3>Nachwuchs</h3>
        <div *ngFor="let wurf of wuerfe" class="wurf">
            <div class="header">
                <img src="../../assets/images/nachwuchs/{{wurf.ID}}.JPG">
                <div class="info">
                    <p>{{wurf.Datum}}</p>
                    <p>{{wurf.Vater}} x {{wurf.Mutter}}</p>
                </div>
            </div>
            <div class="images">
                <ul>
                    <li *ngFor="let nachwuchs of wurf.Nachwuchs">
                        <img src="../../assets/images/nachwuchs/{{wurf.ID}}.{{nachwuchs.ID}}.JPG">
                        <p *ngIf="nachwuchs.Gewicht != null">
                            {{nachwuchs.Rasse}}, {{nachwuchs.Farbe}}, {{nachwuchs.Geschlecht}}, {{nachwuchs.Gewicht}}gr.
                        </p>
                        <p *ngIf="nachwuchs.Gewicht == null">
                            {{nachwuchs.Rasse}}, {{nachwuchs.Farbe}}, {{nachwuchs.Geschlecht}}
                        </p>
                    </li>
                </ul>
            </div>
        </div>
        <div *ngIf="wuerfe == undefined" style="padding: 3em 0 3em 0;">
            <div class="app-loading">
                <div class="logo"></div>
                <svg class="spinner" viewBox="25 25 50 50">
                    <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
                </svg>
            </div>
        </div>
    </div>
</div>