import { Component, OnInit } from '@angular/core';
import { Auth } from '../app/admin/admin.component';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  title = 'grifo';
  logged = false;
  user = '';
  isCollapsed = true;

  constructor(private route: ActivatedRoute, private auth: Auth, private cookieService: CookieService,
    private router: Router, private http: HttpClient) { }

  ngOnInit() {
    this.auth.check().then(ret => {
      this.logged = ret.logged;
      this.user = ret.user;
    });
    let httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=UTF-8'
        })
      };
    const url = "https://grifo.ch/api/update/statistik.php";
    this.route.queryParams.subscribe(params => {
      const param = params['a'];
      if (param !== undefined && parseInt(param) === 1) {
        let body = JSON.stringify({ "Besucher": "A"});
        const response = this.http.post(url, body, httpOptions);
        response.subscribe(res => { });
        this.router.navigate(
          ['.'], 
          { relativeTo: this.route, queryParams: {} }
        );
      }
    });
    if (!this.cookieService.check('Statistik')) {
      this.cookieService.set("Statistik", "", 0, "/", undefined, false, "Lax");
      let body = JSON.stringify({ "Besucher": "X"});
      const response = this.http.post(url, body, httpOptions);
      response.subscribe(res => { });
    }
  }

  openHamburger() {
    this.isCollapsed = false;
  }

  closeHamburger() {
    this.isCollapsed = true;
  }

}
