<div class="home">
    <div class="sidebar">
        <ul>
            <li [ngClass]="sidebarStyle('news')">
                <a (click)="changeContent('news')">News</a>
            </li>
            <li [ngClass]="sidebarStyle('calculator')">
                <a (click)="changeContent('calculator')">Wurfrechner</a>
            </li>
            <li>
                <a href="mailto:meerschweinchen@grifo.ch">Kontakt</a></li>
            <li [ngClass]="sidebarStyle('links')">
                <a (click)="changeContent('links')">Links</a>
            </li>
        </ul>
    </div>
    <div *ngIf="news" class="news">
        <div *ngIf="logged" id="add">
            <a routerLink="/add/news">News hinzufügen</a>
        </div>
        <div class="title">
            <h3>News</h3>
        </div>
        <p id="news_heading">
            Meerschweinchen begleiten mich schon seit meiner Kindheit und seit 2005 besitze ich eine kleine
            Liebhaberzucht dieser putzigen Tierchen. Meine Liebe gilt der tollen Rasse US-Teddy, da diese sehr
            zutraulich und ruhig sind. Nun haben wir endlich wieder eine HP, welche mein zweitältester Sohn für mich
            kreiert hat!
        </p>
        <div *ngIf="data_news != undefined"  class="pageNavigation">
            <a *ngIf="currentPage > 1" (click)="previousPage()"><img src="../../assets/images/icons/left.png"></a>
            <p>Seite {{currentPage}} von {{pages}}</p>
            <a *ngIf="currentPage < pages" (click)="nextPage()"><img src="../../assets/images/icons/right.png"></a>
        </div>
        <div *ngIf="data_news == undefined" style="padding: 3em 0 3em 0;">
            <div class="app-loading">
                <div class="logo"></div>
                <svg class="spinner" viewBox="25 25 50 50">
                    <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
                </svg>
            </div>
        </div>
        <div *ngFor="let d of data_news">
            <div class="news_entry">
                <div *ngIf="visibility[d.ID]">
                    <div class="news_header">
                        <p class="news_title">{{d.Titel}}</p>
                        <div class="news_date">
                            <p>{{d.DatumFormatted}}</p>
                            <div>
                                <a *ngIf="logged" (click)="edit(d.ID)">
                                    <img src="../../assets/images/icons/edit.png">
                                </a>
                                <a *ngIf="logged" routerLink="/delete/news/{{d.ID}}">
                                    <img src="../../assets/images/icons/delete.png">
                                </a>
                            </div>
                        </div>
                    </div>
                    <span *ngFor="let content of d.Content">
                        <p *ngIf="!content.image" class="news_text">{{content.value}}</p>
                        <img *ngIf="content.image" src="{{content.value}}">
                    </span>
                </div>
                <div *ngIf="!visibility[d.ID]" class="manage">
                    <div *ngIf="!submitted">
                        <form name="form" (ngSubmit)="myForm.form.valid && update(d.ID, myForm.form.value)"
                            #myForm="ngForm" novalidate>
                            <p>Datum:</p>
                            <input type="date" name="date" class="form-control" [(ngModel)]="model.date" #date="ngModel"
                                [ngClass]="{ 'is-invalid': myForm.submitted && date.invalid }" required>
                            <div *ngIf="myForm.submitted && date.invalid" class="invalid_feedback">
                                <div *ngIf="date.errors.required">Datum wird benötigt!</div>
                            </div>
                            <p>Titel:</p>
                            <input type="text" id="title_input" name="title" class="form-control" autocomplete="off"
                                [(ngModel)]="model.title" #title="ngModel"
                                [ngClass]="{ 'is-invalid': myForm.submitted && title.invalid }" required>
                            <div *ngIf="myForm.submitted && title.invalid" class="invalid_feedback">
                                <div *ngIf="title.errors.required">Titel wird benötigt!</div>
                            </div>
                            <p>Text:</p>
                            <textarea rows="8" id="text_input" name="text" class="form-control" [(ngModel)]="model.text"
                                #text="ngModel" [ngClass]="{ 'is-invalid': myForm.submitted && text.invalid }"
                                required></textarea>
                            <div *ngIf="myForm.submitted && text.invalid" class="invalid_feedback">
                                <div *ngIf="text.errors.required">Text wird benötigt!</div>
                            </div>
                            <div class="submit_button">
                                <button>Speichern</button>
                                <button type="button" (click)="cancel(d.ID)">Abbrechen</button>
                            </div>
                        </form>
                    </div>
                    <div *ngIf="submitted && !response">
                        <div class="app-loading" style="margin: 3em 0 3em 0;">
                            <div class="logo"></div>
                            <svg class="spinner" viewBox="25 25 50 50">
                                <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2"
                                    stroke-miterlimit="10" />
                            </svg>
                        </div>
                    </div>
                    <div *ngIf="submitted && response" class="submit_button">
                        <div *ngIf="success">
                            <p>Erfolgreich ausgeführt!</p>
                        </div>
                        <div *ngIf="!success" style="color: red;">
                            <p>Fehler! Versuch' es später nochmal.</p>
                        </div>
                        <button type="button" (click)="reset()" style="margin-top: 0.5em;">OK</button>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="data_news != undefined" class="pageNavigation">
            <a *ngIf="currentPage > 1" (click)="previousPage()"><img src="../../assets/images/icons/left.png"></a>
            <p>Seite {{currentPage}} von {{pages}}</p>
            <a *ngIf="currentPage < pages" (click)="nextPage()"><img src="../../assets/images/icons/right.png"></a>
        </div>
        <div class="empty" *ngIf="data_news.length == 0">
            <p style="font-style: italic;">Hier sind zurzeit keine Einträge vorhanden.</p>
        </div>
    </div>
    <div *ngIf="calculator" class="news">
        <div class="title">
            <h3>Wurfrechner</h3>
        </div>
        <p>Dieser Wurfrechner rechnet mit 68 Tagen ab dem Deckzeitpunkt.</p>
        <label>Deckzeitpunkt</label><br>
        <input id="day" type="date">
        <div *ngIf="calcError" class="invalid_feedback">Bitte ein gültiges Datum auswählen!</div>
        <div *ngIf="calculatedBirth" class="birthDay"><br>{{calculatedBirth}}</div>
        <div *ngIf="calculating" style="width: min-content;">
            <div class="app-loading" style="margin: 3em 0 3em 0;">
                <div class="logo"></div>
                <svg class="spinner" viewBox="25 25 50 50">
                    <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2"
                        stroke-miterlimit="10" />
                </svg>
            </div>
        </div>
        <div class="submit_button">
            <button (click)="calcBirth()">Berechnen</button>
        </div>
    </div>
    <div *ngIf="links" class="news">
        <div class="title">
            <h3>Links</h3>
        </div>
        <div *ngIf="data_links == undefined" style="padding: 3em 0 3em 0;">
            <div class="app-loading">
                <div class="logo"></div>
                <svg class="spinner" viewBox="25 25 50 50">
                    <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
                </svg>
            </div>
        </div>
        <div *ngFor="let d of data_links">
            <div class="news_entry">
                <div class="news_header">
                    <p class="news_title">{{d.Titel}}</p>
                </div>
                <p class="news_text">Homepage: <a href="{{d.URL}}" target="_blank">{{d.URL}}</a></p>
            </div>
        </div>
        <div class="empty" *ngIf="data_links.length == 0">
            <p style="font-style: italic;">Hier sind zurzeit keine Einträge vorhanden.</p>
        </div>
    </div>
</div>