import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auslauf',
  templateUrl: './auslauf.component.html',
  styleUrls: ['./auslauf.component.css']
})
export class AuslaufComponent implements OnInit {

  aussengehegeMuminek = true;
  aussengehegeMerlin = false;
  balkon = false;

  constructor() { }

  ngOnInit() { }

  sidebarStyle(link: string) {
    if (link == "aussengehegeMuminek" && this.aussengehegeMuminek || link == "aussengehegeMerlin" && this.aussengehegeMerlin
      || link == "balkon" && this.balkon) {
      return "active";
    }
    return "";
  }

  changeContent(link: string) {
    if (link == "aussengehegeMuminek") {
      this.aussengehegeMuminek = true;
      this.aussengehegeMerlin = false;
      this.balkon = false;
    } else if (link == "aussengehegeMerlin") {
      this.aussengehegeMuminek = false;
      this.aussengehegeMerlin = true;
      this.balkon = false;
    } else if (link == "balkon") {
      this.aussengehegeMuminek = false;
      this.aussengehegeMerlin = false;
      this.balkon = true;
    }
  }

}
