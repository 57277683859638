import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Auth } from '../admin/admin.component';

@Component({
  selector: 'app-mannschaft',
  templateUrl: './mannschaft.component.html',
  styleUrls: ['./mannschaft.component.css']
})
export class MannschaftComponent implements OnInit {

  data_liebhaber;
  data_zucht_m;
  data_zucht_w;
  data_admin: Array<Object>;
  chachedTyp = "";
  liebhaber = true;
  zucht_m = false;
  zucht_w = false;
  logged = false;
  admin = false;
  edit = {};
  delete = {};
  loading = false;
  success = -1;
  buttonsVisible = true;

  constructor(private route: ActivatedRoute, private auth: Auth, private http: HttpClient) { }
  
  ngOnInit() {
    this.getLiebhaber();
    this.route.queryParams.subscribe(params => {
      const param = params['typ'];
      if (param == "zuchtmaennchen") {
        if (this.data_zucht_m == undefined) {
          this.getZuchtM();
        }
        this.liebhaber = false;
        this.zucht_m = true;
      } else if (param == "zuchtweibchen") {
        if (this.data_zucht_w == undefined) {
          this.getZuchtW();
        }
        this.liebhaber = false;
        this.zucht_w = true;
      }
    });
    this.auth.check().then(ret => this.logged = ret.logged);
  }

  async getLiebhaber() {
    const url = "https://grifo.ch/api/get/mannschaft.php?typ=liebhaber";
    const response = await fetch(url);
    this.data_liebhaber = await response.json();
  }
  async getZuchtM() {
    const url = "https://grifo.ch/api/get/mannschaft.php?typ=zuchttier&geschlecht=m";
    const response = await fetch(url);
    this.data_zucht_m = await response.json();
  }

  async getZuchtW() {
    const url = "https://grifo.ch/api/get/mannschaft.php?typ=zuchttier&geschlecht=w";
    const response = await fetch(url);
    this.data_zucht_w = await response.json();
  }

  changeContent(link: string) {
    if (link == "liebhaber") {
      history.replaceState("", "page 1", "mannschaft?typ=liebhaber");
      this.liebhaber = true;
      this.zucht_m = false;
      this.zucht_w = false;
    } else if (link == "zucht_m") {
      history.replaceState("", "page 2", "mannschaft?typ=zuchtmaennchen");
      if (this.data_zucht_m == undefined) {
        this.getZuchtM();
      }
      this.liebhaber = false;
      this.zucht_m = true;
      this.zucht_w = false;
    } if (link == "zucht_w") {
      history.replaceState("", "page 3", "mannschaft?typ=zuchtweibchen");
      if (this.data_zucht_w == undefined) {
        this.getZuchtW();
      }
      this.liebhaber = false;
      this.zucht_m = false;
      this.zucht_w = true;
    }
  }

  sidebarStyle(link: string) {
    if (link == "liebhaber" && this.liebhaber && !this.zucht_m && !this.zucht_w ||
      link == "zucht_m" && !this.liebhaber && this.zucht_m && !this.zucht_w ||
      link == "zucht_w" && !this.liebhaber && !this.zucht_m && this.zucht_w) {
      return "active";
    }
    return "";
  }

  async adminVisible(isVisible) {
    if (isVisible) {
      if (this.data_liebhaber == undefined) {
        await this.getLiebhaber();
      }
      if (this.data_zucht_m == undefined) {
        await this.getZuchtM();
      }
      if (this.data_zucht_w == undefined) {
        await this.getZuchtW();
      }
      this.composeAdminData();
    }
    this.admin = isVisible;
  }

  composeAdminData() {
    this.data_admin = [];
    this.edit = {};
    for (let d of this.data_liebhaber) {
      this.data_admin.push(d);
      this.edit[d.ID] = false;
    }
    for (let d of this.data_zucht_m) {
      this.data_admin.push(d);
      this.edit[d.ID] = false;
    }
    for (let d of this.data_zucht_w) {
      this.data_admin.push(d);
      this.edit[d.ID] = false;
    }
  }

  isEditing(id, editing) {
    this.edit[id] = editing;
    if (editing) {
      this.buttonsVisible = false;
      this.success = -1;
      for (let d of this.data_admin) {
        if (d["ID"] == id) {
          this.chachedTyp = d["Typ"];
        }
      }
    }
    if (!editing) {
      this.buttonsVisible = true;
      if (this.chachedTyp != "") {
        for (let d of this.data_admin) {
          if (d["ID"] == id) {
            d["Typ"] = this.chachedTyp;
          }
        }
      }
    }
  }

  editing(id) {
    return this.edit[id];
  }

  saveEdit(data) {
    let body = JSON.stringify({ 'ID': data["ID"], 'Typ': data["Typ"] });
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=UTF-8'
      })
    };
    this.loading = true;
    const url = "https://grifo.ch/api/update/mannschaft.php";
    const response = this.http.post(url, body, httpOptions);
    response.subscribe(res => {
      this.loading = false;
      if (res['returnCode'] == "OK") {
        this.success = 1;
      } else {
        this.success = 0;
        if (this.chachedTyp != "") {
          for (let d of this.data_admin) {
            if (d["ID"] == data["ID"]) {
              d["Typ"] = this.chachedTyp;
            }
          }
        }
      }
    });
  }

  ok(id) {
    this.edit[id] = false;
    this.success = -1;
    this.buttonsVisible = true;
  }

  isDeleting(id, deleting) {
    this.delete[id] = deleting;
    if (deleting) {
      this.buttonsVisible = false;
      this.success = -1;
    }
    if (!deleting) {
      this.buttonsVisible = true;
    }
  }

  deleting(id) {
    return this.delete[id];
  }

  deleteEdit(data) {

  }

}

@Component({
  selector: 'app-mannschaft',
  templateUrl: './mannschaft.component.detail.html',
  styleUrls: ['./mannschaft.component.css']
})
export class MannschaftDetailComponent implements OnInit {

  data;
  empty = false;

  private url = "https://grifo.ch/api/get/meerschweinchen.php";

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.getData();
  }

  async getData() {
    this.empty = false;
    this.url += ("?id=" + this.route.snapshot.paramMap.get('id'));
    const response = await fetch(this.url);
    const json = await response.json();
    this.data = json[0];
    if (json.length == 0) {
      this.empty = true;
    }
  }

}