<div class="mannschaft">
    <div *ngIf="data == undefined && !empty">
        <div style="padding-top: 5em;"></div>
        <div class="app-loading">
            <div class="logo"></div>
            <svg class="spinner" viewBox="25 25 50 50">
                <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
            </svg>
        </div>
    </div>
    <div *ngIf="data != undefined" class="detail">
        <h3 >{{data.Name}}</h3>
        <div class="description">
            <div>
                <div class="desc_title">
                    <p>Geschlecht:</p>
                    <p>Farbe:</p>
                    <p>Rasse:</p>
                    <p>Geburtstag:</p>
                    <p>Zucht:</p>
                </div>
                <div class="desc_value">
                    <p *ngIf="data.Name != 'Fluffy' && data.Geschlecht == 'M'">Männlich</p>
                    <p *ngIf="data.Name == 'Fluffy'">Männlich (Frühkastrat)</p>
                    <p *ngIf="data.Geschlecht == 'W'">Weiblich</p>
                    <p>{{data.Farbe}}</p>
                    <p>{{data.Rasse}}</p>
                    <p>{{data.Geburtstag}}</p>
                    <p>{{data.Zucht}}</p>
                </div>
            </div>
        </div>
        <div class="desc_image">
            <img src="../../assets/images/meerschweinchen/{{data.ID}}.JPG">
        </div>
    </div>
    <div class="empty" style="text-align: center; font-style: italic;">
        <p *ngIf="empty">Dieser Eintrag ist nicht vorhanden.</p>
    </div>
</div>