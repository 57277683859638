import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Auth } from '../admin/admin.component';

@Component({
  selector: 'app-abgabe',
  templateUrl: './abgabe.component.html',
  styleUrls: ['./abgabe.component.css']
})
export class AbgabeComponent implements OnInit {

  logged = false;
  admin = false;
  months = ["", "Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];
  abgabe;
  weibchen;
  kastraten;
  reserviert;

  constructor(private auth: Auth, private http: HttpClient) { }

  ngOnInit() {
    this.getAbgabe();
    this.auth.check().then(ret => this.logged = ret.logged);
  }

  async getAbgabe() {
    const url = "https://grifo.ch/api/get/abgabe.php";
    const response = await fetch(url);
    const json = await response.json();
    this.abgabe = json;
    this.weibchen = [];
    this.kastraten = [];
    this.reserviert = [];
    for (let j of json) {
      if (j.Nachwuchs == 1) {
        let year = j.Geburtstag.substring(0, 4);
        let month = this.months[parseInt(j.Geburtstag.substring(5, 7))];
        let day = j.Geburtstag.substring(8);
        j.Geburtstag = "" + day + ". " + month + " " + year;
      }
      if (j.Reserviert == 0) {
        if (j.Geschlecht == "W") {
          this.weibchen.push(j);
        } else if (j.Geschlecht == "M") {
          this.kastraten.push(j);
        }
      } else if (j.Reserviert == 1) {
        this.reserviert.push(j);
      }
    }
  }

  adminVisible(isVisible) {
    this.admin = isVisible;
  }

}
