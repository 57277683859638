import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Auth } from '../admin/admin.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  data_news;
  logged = false;
  model = {
    date: '',
    title: '',
    text: ''
  };
  visibility = {};
  submitted = false;
  success = false;
  response = false;
  news = true;
  calculator = false;
  links = false;
  data_links;
  months = ["", "Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];
  pages = 1;
  currentPage = 1;
  entriesPerPage = 5;
  data = [];
  calcError = false;
  calculatedBirth = "";
  calculating = false;

  constructor(private auth: Auth, private http: HttpClient) { }

  ngOnInit() {
    this.getNews();
    this.auth.check().then(ret => this.logged = ret.logged);
  }

  async getNews() {
    const url = "https://grifo.ch/api/get/blog.php";
    const response = await fetch(url);
    const json = await response.json();
    this.pages = Math.ceil(json.length / this.entriesPerPage);
    for (let j of json) {
      let year = j.Datum.substring(0, 4);
      let month = this.months[parseInt(j.Datum.substring(5, 7))];
      let day = j.Datum.substring(8);
      j.DatumFormatted = "" + day + ". " + month + " " + year;
      j.Content = [];
      if (j.Bilder != null) {
        const parts = j.Text.split("-IMG-");
        const imgs = j.Bilder.split(",");
        for (let i in parts) {
          if (parts[i].includes("\n")) {
            const lines = parts[i].split("\n");
            for (let l = 0; l < lines.length; l++) {
              j.Content.push({ image: false, value: lines[l] });
            }
          } else {
            j.Content.push({ image: false, value: parts[i] });
          }
          if (i < imgs.length && parseInt(i) < parts.length - 1) {
            j.Content.push({ image: true, value: imgs[i] });
          }
        }
      } else {
        if (j.Text.includes("\n")) {
          const parts = j.Text.split("\n");
          for (let i = 0; i < parts.length; i++) {
            j.Content.push({ image: false, value: parts[i] });
          }
        } else {
          j.Content.push({ image: false, value: j.Text });
        }
      }
      this.data.push(j);
    }
    for (let d of this.data) {
      if (this.visibility[d.ID] === undefined) { this.visibility[d.ID] = true; }
    }
    this.updatePageContent();
  }

  async getLinks() {
    const url = "https://grifo.ch/api/get/links.php";
    const response = await fetch(url);
    this.data_links = await response.json();
  }

  edit(id: number) {
    for (let d of this.data_news) {
      if (d.ID == id) {
        this.model['ID'] = d.ID;
        this.model['date'] = d.Datum;
        this.model['title'] = d.Titel;
        this.model['text'] = d.Text;
      }
    }
    this.visibility[id] = false;
  }

  cancel(id: number) {
    this.visibility[id] = true;
  }

  update(id: number, data: any) {
    this.submitted = true;
    let body = JSON.stringify({ 'ID': id, 'Datum': data.date, 'Titel': data.title, 'Text': data.text });
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=UTF-8'
      })
    };
    const url = "https://grifo.ch/api/update/blog.php";
    const response = this.http.post(url, body, httpOptions);
    response.subscribe(res => {
      this.response = true;
      if (res['returnCode'] == "OK") {
        this.success = true;
      } else {
        this.success = false;
      }
    });
  }

  reset() {
    if (this.success) {
      window.location.reload();
    } else {
      this.response = false;
      this.success = false;
      this.submitted = false;
    }
  }

  changeContent(link: string) {
    if (link == "news") {
      this.news = true;
      this.calculator = false;
      this.links = false;
    } else if (link == "calculator") {
      this.news = false;
      this.calculator = true;
      this.links = false;
    } else if (link == "links") {
      if (this.data_links == undefined) {
        this.getLinks();
      }
      this.news = false;
      this.calculator = false;
      this.links = true;
    }
  }

  sidebarStyle(link: string) {
    if (link == "news" && this.news || link == "calculator" && this.calculator || link == "links" && this.links) {
      return "active";
    }
    return "";
  }

  updatePageContent() {
    this.data_news = [];
    for (let i = 0; i < this.entriesPerPage; i++) {
      let index = this.entriesPerPage * (this.currentPage - 1) + i;
      if (index < this.data.length) {
        this.data_news.push(this.data[index]);
      } else {
        break;
      }
    }
  }

  nextPage() {
    this.currentPage++;
    this.updatePageContent();
  }

  previousPage() {
    this.currentPage--;
    this.updatePageContent();
  }

  calcBirth() {
    let day = document.getElementById("day")['value'];
    this.calcError = false;
    this.calculatedBirth = "";
    if (day != null && day.length > 0) {
      let body = JSON.stringify({ 'day': day });
      let httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=UTF-8'
        })
      };
      const url = "https://grifo.ch/api/calc/birth.php";
      const response = this.http.post(url, body, httpOptions);
      this.calculating = true;
      response.subscribe(res => {
        this.calculating = false;
        if (res['returnCode'] === "OK") {
          this.calculatedBirth = "Der Wurf wird um den " + res['birthDay'] + " erwartet.";
        } else {
          this.calcError = true;
        }
      });
    } else {
      this.calcError = true;
    }
  }

}
