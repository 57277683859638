import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-verpaarung',
  templateUrl: './verpaarung.component.html',
  styleUrls: ['./verpaarung.component.css']
})
export class VerpaarungComponent implements OnInit {

  months = ["", "Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];
  verpaarungen;

  constructor() { }

  ngOnInit() {
    this.getVerpaarungen();
  }

  async getVerpaarungen() {
    const url = "https://grifo.ch/api/get/verpaarungen.php";
    const response = await fetch(url);
    const json = await response.json();
    let data = [];
    for (let j of json) {
      let year = j.Geburt.substring(0, 4);
      let month = this.months[parseInt(j.Geburt.substring(5, 7))];
      let day = j.Geburt.substring(8);
      j.Geburt = "" + day + ". " + month + " " + year;
      year = j.Zusammen.substring(0, 4);
      month = this.months[parseInt(j.Zusammen.substring(5, 7))];
      day = j.Zusammen.substring(8);
      j.Zusammen = "" + day + ". " + month + " " + year;
      data.push(j);
    }
    this.verpaarungen = data;
  }

}
