<div id="verpaarungen">
    <div class="sidebar">
        <ul></ul>
    </div>
    <div class="verpaarungen">
        <h3>Verpaarungen</h3>
        <div *ngIf="verpaarungen != undefined && verpaarungen.length != 0">
            <p style="font-weight: bold;">Wir erwarten aus folgenden Verpaarungen Nachwuchs:</p>
            <div *ngFor="let v of verpaarungen" class="verpaarung">
                <ul>
                    <li>
                        <a routerLink="/meerschweinchen/{{v.Frau}}">
                            <img src="../../assets/images/meerschweinchen/{{v.Frau}}.JPG">
                            <p class="name">{{v.NameF}}</p>
                        </a>
                    </li>
                    <li>
                        <a routerLink="/meerschweinchen/{{v.Mann}}">
                            <img src="../../assets/images/meerschweinchen/{{v.Mann}}.JPG">
                            <p class="name">{{v.NameM}}</p>
                        </a>
                    </li>
                </ul>
                <p>Mit der Geburt der Babys ist frühestens ab dem {{v.Geburt}} zu rechnen.</p>
                <p>(zusammen seit {{v.Zusammen}})</p>
            </div>
        </div>
        <div *ngIf="verpaarungen != undefined && verpaarungen.length == 0">
            <p style="font-style: italic;">Zurzeit gibt es keine Verpaarungen.</p>
        </div>
        <div *ngIf="verpaarungen == undefined" style="padding: 3em 0 3em 0;">
            <div class="app-loading">
                <div class="logo"></div>
                <svg class="spinner" viewBox="25 25 50 50">
                    <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
                </svg>
            </div>
        </div>
    </div>
</div>