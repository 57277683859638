<div class="admin">
    <div class="login">
        <h3>Login</h3>
        <form *ngIf="!sent" name="form" (ngSubmit)="myForm.form.valid && onSubmit(myForm.form.value)" #myForm="ngForm" novalidate>
            <div class="login_value">

                <p>Benutzername:</p>
                <input type="text" name="user" class="form-control" [(ngModel)]="model.user" #user="ngModel"
                    [ngClass]="{ 'is-invalid': myForm.submitted && user.invalid }" required>
                <div *ngIf="myForm.submitted && user.invalid" class="invalid_feedback">
                    <div *ngIf="user.errors.required">Benutzername wird benötigt!</div>
                </div>
                
                <p>Passwort:</p>
                <input type="password" name="passwd" class="form-control" [(ngModel)]="model.passwd" #passwd="ngModel"
                    [ngClass]="{ 'is-invalid': myForm.submitted && passwd.invalid }" required>
                <div *ngIf="myForm.submitted && passwd.invalid" class="invalid_feedback">
                    <div *ngIf="passwd.errors.required">Passwort wird benötigt!</div>
                </div>

            </div>
            <div class="submit_button">
                <button>Anmelden</button>
                <button (click)="cancel()" type="button">Abbrechen</button>
            </div>
        </form>
        <div *ngIf="submitted" class="invalid_feedback">
            <p>{{loggedMessage}}</p>
        </div>
        <div *ngIf="sent" class="wait">
            <div class="app-loading">
                <div class="logo"></div>
                <svg class="spinner" viewBox="25 25 50 50">
                    <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
                </svg>
            </div>
        </div>
    </div>
</div>