<div id="abgabe">
    <div class="sidebar">
        <ul></ul>
    </div>
    <div class="abgabe">
        <div id="info">
            <div *ngIf="logged && !admin" id="add">
                <a (click)="adminVisible(true)">Abgaben bearbeiten</a>
            </div>
            <div *ngIf="logged && admin" id="add">
                <a (click)="adminVisible(false)">Zurück</a>
            </div>
            <h3 *ngIf="!admin">Abgabe</h3>
            <h3 *ngIf="admin">Abgaben bearbeiten</h3>
            <p *ngIf="!admin">
                Meerschweinchen sind keine Kuscheltiere und mögen es überhaupt nicht, herumgetragen zu werden. Zum
                Beobachten sind sie jedoch umso interessanter. Sie sind sehr gesellig und fühlen sich in einem Rudel
                ab drei Tieren wohl. Man kann Weibchen zusammen mit einem oder mehreren Frühkastraten halten, aber
                auch eine reine Frühkastratengruppe ist kein Problem. Die Haltung von mehreren unkastrierten Männchen
                ist nicht zu empfehlen, da dies mit grosser Wahrscheinlichkeit zu Streitereien führen wird.
            </p>
            <p *ngIf="!admin">
                Wir wünschen uns, dass unsere Abgabetiere in eine artgerechte Haltung mit viel Platz ziehen dürfen.<br>
                Bei Fragen stehen wir jederzeit gerne zur Verfügung.
            </p>
            <h4 *ngIf="!admin">Preise</h4>
            <table *ngIf="!admin">
                <tr>
                    <td>Weibchen:</td>
                    <td>60.-</td>
                </tr>
                <tr>
                    <td>Frühkastrat:</td>
                    <td>70.-</td>
                </tr>
            </table>
        </div>
        <div *ngIf="!admin">
            <h3>Weibchen</h3>
            <p *ngIf="weibchen != undefined && weibchen.length == 0" class="empty">
                Momentan gibt es keine Weibchen zur Abgabe.
            </p>
            <div *ngIf="weibchen != undefined && weibchen.length != 0" class="images">
                <ul>
                    <li *ngFor="let w of weibchen">
                        <span *ngIf="w.Nachwuchs == 0 && w.Sichtbar == 1">
                            <a routerLink="/meerschweinchen/{{w.Meerschweinchen}}">
                                <img src="../../assets/images/meerschweinchen/{{w.Meerschweinchen}}.JPG">
                                <p>{{w.Name}}</p>
                            </a>
                        </span>
                        <span *ngIf="w.Nachwuchs == 0 && w.Sichtbar == 0">
                            <img src="../../assets/images/meerschweinchen/{{w.Meerschweinchen}}.JPG">
                            <p style="font-weight: bold;">{{w.Rasse}}, {{w.Farbe}}, {{w.Geburtstag}}</p>
                        </span>
                        <span *ngIf="w.Nachwuchs == 1">
                            <img src="../../assets/images/nachwuchs/{{w.Wurf}}.{{w.Meerschweinchen}}.JPG">
                            <p style="font-weight: bold;">{{w.Rasse}}, {{w.Farbe}}, {{w.Geburtstag}}</p>
                        </span>
                    </li>
                </ul>
            </div>
            <div *ngIf="weibchen == undefined" style="padding: 3em 0 3em 0;">
                <div class="app-loading">
                    <div class="logo"></div>
                    <svg class="spinner" viewBox="25 25 50 50">
                        <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2"
                            stroke-miterlimit="10" />
                    </svg>
                </div>
            </div>
        </div>
        <div *ngIf="!admin">
            <h3>Frühkastraten</h3>
            <p *ngIf="kastraten != undefined && kastraten.length == 0" class="empty">
                Momentan gibt es keine Frühkastraten zur Abgabe.
            </p>
            <div *ngIf="kastraten != undefined && kastraten.length != 0" class="images">
                <ul>
                    <li *ngFor="let k of kastraten">
                        <span *ngIf="k.Nachwuchs == 0 && k.Sichtbar == 1">
                            <a routerLink="/meerschweinchen/{{k.Meerschweinchen}}">
                                <img src="../../assets/images/meerschweinchen/{{k.Meerschweinchen}}.JPG">
                                <p>{{k.Name}}</p>
                            </a>
                        </span>
                        <span *ngIf="k.Nachwuchs == 0 && k.Sichtbar == 0">
                            <img src="../../assets/images/meerschweinchen/{{k.Meerschweinchen}}.JPG">
                            <p style="font-weight: bold;">{{k.Rasse}}, {{k.Farbe}}, {{k.Geburtstag}}</p>
                        </span>
                        <span *ngIf="k.Nachwuchs == 1">
                            <img src="../../assets/images/nachwuchs/{{k.Wurf}}.{{k.Meerschweinchen}}.JPG">
                            <p style="font-weight: bold;">{{k.Rasse}}, {{k.Farbe}}, {{k.Geburtstag}}</p>
                        </span>
                    </li>
                </ul>
            </div>
            <div *ngIf="kastraten == undefined" style="padding: 3em 0 3em 0;">
                <div class="app-loading">
                    <div class="logo"></div>
                    <svg class="spinner" viewBox="25 25 50 50">
                        <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2"
                            stroke-miterlimit="10" />
                    </svg>
                </div>
            </div>
        </div>
        <div *ngIf="!admin">
            <h3>Reserviert</h3>
            <p *ngIf="reserviert != undefined && reserviert.length == 0" class="empty">
                Momentan gibt es keine Reservationen.
            </p>
            <div *ngIf="reserviert != undefined && reserviert.length != 0" class="images">
                <ul>
                    <li *ngFor="let r of reserviert">
                        <span *ngIf="r.Nachwuchs == 0 && r.Sichtbar == 1">
                            <a routerLink="/meerschweinchen/{{r.Meerschweinchen}}">
                                <img src="../../assets/images/meerschweinchen/{{r.Meerschweinchen}}.JPG">
                                <p style="margin: 0;">{{r.Name}}</p>
                            </a>
                            <p *ngIf="r.Fuer != null" style="margin: 0 0 1.5em 0;">
                                Reserviert für <span style="font-style: italic;">{{r.Fuer}}</span>
                            </p>
                            <p *ngIf="r.Fuer == null" style="margin: 0 0 1.5em 0; visibility: hidden;">
                                Reserviert für -
                            </p>
                        </span>
                        <span *ngIf="r.Nachwuchs == 0 && r.Sichtbar == 0">
                            <img src="../../assets/images/meerschweinchen/{{r.Meerschweinchen}}.JPG">
                            <p style="font-weight: bold; margin: 0;">{{r.Rasse}}, {{r.Farbe}}, {{r.Geburtstag}}</p>
                            <p *ngIf="r.Fuer != null" style="margin: 0 0 1.5em 0;">
                                Reserviert für <span style="font-style: italic;">{{r.Fuer}}</span>
                            </p>
                            <p *ngIf="r.Fuer == null" style="margin: 0 0 1.5em 0; visibility: hidden;">
                                Reserviert für -
                            </p>
                        </span>
                        <span *ngIf="r.Nachwuchs == 1">
                            <img src="../../assets/images/nachwuchs/{{r.Wurf}}.{{r.Meerschweinchen}}.JPG">
                            <p style="font-weight: bold; margin: 0;">{{r.Rasse}}, {{r.Farbe}}, {{r.Geburtstag}}</p>
                            <p *ngIf="r.Fuer != null" style="margin: 0 0 1.5em 0;">
                                Reserviert für <span style="font-style: italic;">{{r.Fuer}}</span>
                            </p>
                            <p *ngIf="r.Fuer == null" style="margin: 0 0 1.5em 0; visibility: hidden;">
                                Reserviert für -
                            </p>
                        </span>
                    </li>
                </ul>
            </div>
            <div *ngIf="reserviert == undefined" style="padding: 3em 0 3em 0;">
                <div class="app-loading">
                    <div class="logo"></div>
                    <svg class="spinner" viewBox="25 25 50 50">
                        <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2"
                            stroke-miterlimit="10" />
                    </svg>
                </div>
            </div>
        </div>
        <div id="admin" *ngIf="admin">
            <a routerLink="/add/abgabe">Neue Abgabe</a>
            <span *ngIf="abgabe != undefined && abgabe.length > 0"><br><br></span>
            <table *ngIf="abgabe != undefined && abgabe.length > 0">
                <thead>
                    <tr>
                        <th>Meerschweinchen</th>
                        <th>Nachwuchs</th>
                        <th>Reserviert</th>
                        <th>Für</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let a of abgabe">
                        <td>
                            <span *ngIf="a.Nachwuchs == 1">
                                <img src="../../assets/images/nachwuchs/{{a.Wurf}}.{{a.Meerschweinchen}}.JPG">
                                <p>{{a.Geschlecht}}, {{a.Rasse}}, {{a.Farbe}}, {{a.Geburtstag}}</p>
                            </span>
                            <span *ngIf="a.Nachwuchs == 0">
                                <img src="../../assets/images/meerschweinchen/{{a.Meerschweinchen}}.JPG">
                                <p>{{a.Name}}</p>
                            </span>
                        </td>
                        <td>
                            <span *ngIf="a.Nachwuchs == 1">Ja</span>
                            <span *ngIf="a.Nachwuchs == 0">Nein</span>
                        </td>
                        <td>
                            <span *ngIf="a.Reserviert == 1">Ja</span>
                            <span *ngIf="a.Reserviert == 0">Nein</span>
                        </td>
                        <td>
                            <span *ngIf="a.Fuer != null">{{a.Fuer}}</span>
                            <span *ngIf="a.Fuer == null" style="font-style: italic; opacity: 0.5;">leer</span>
                        </td>
                        <td>
                            <a style="margin-right: 0.5em;">
                                <img src="../../assets/images/icons/edit.png">
                            </a>
                            <a>
                                <img src="../../assets/images/icons/delete.png">
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>