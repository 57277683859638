<div id="auslauf">
    <div class="sidebar">
        <ul>
            <li [ngClass]="sidebarStyle('aussengehegeMuminek')">
                <a (click)="changeContent('aussengehegeMuminek')">Aussengehege Dino</a>
            </li>
            <li [ngClass]="sidebarStyle('aussengehegeMerlin')">
                <a (click)="changeContent('aussengehegeMerlin')">Aussengehege Amadeo</a>
            </li>
            <li [ngClass]="sidebarStyle('balkon')">
                <a (click)="changeContent('balkon')">Balkon</a>
            </li>
        </ul>
    </div>
    <div class="auslauf">
        <div *ngIf="aussengehegeMuminek">
            <h3>Aussengehege Dino</h3>
            <p style="font-weight: bold;">Hier wohnt unser Zuchtmännchen zusammen mit seinem Kumpel (Frühkastrat). Ab
                und zu sind auch Weibchen zu
                Besuch. Das Gehege hat eine Grösse von ca. 8m&#178; und bietet viel Abwechslung mit verschiedenen
                Unterschlüpfen und natürlich auch viel Platz zum Rennen!</p>
            <img src="../../assets/images/auslauf/aussengehege/IMG_4283.JPG">
            <img src="../../assets/images/auslauf/aussengehege/IMG_4285.JPG">
            <img src="../../assets/images/auslauf/aussengehege/IMG_4294.JPG">
            <img src="../../assets/images/auslauf/aussengehege/IMG_4297.JPG">
            <img src="../../assets/images/auslauf/aussengehege/P9110517.JPG">
            <img src="../../assets/images/auslauf/aussengehege/P9110518.JPG">
            <img src="../../assets/images/auslauf/aussengehege/P9110523.JPG">
            <img src="../../assets/images/auslauf/aussengehege/P9110525.JPG">
            <img src="../../assets/images/auslauf/aussengehege/P9110531.JPG">
        </div>
        <div *ngIf="aussengehegeMerlin">
            <h3>Aussengehege Amadeo</h3>
            <p style="font-weight: bold;">Auch Amadeo hat ein eigenes Aussengehege. Hier kann er
                zusammen mit seinen "Mitbewohnern" sein Reich auskundschaften. Sein Gehege hat ebenfalls
                viele Verstecke, die so natürlich wie möglich gemacht wurden.</p>
            <img src="../../assets/images/auslauf/aussengehege/IMG_4740.JPG">
            <img src="../../assets/images/auslauf/aussengehege/IMG_4737.JPG">
            <img src="../../assets/images/auslauf/aussengehege/IMG_4734.JPG">
            <img src="../../assets/images/auslauf/aussengehege/IMG_4738.JPG">
        </div>
        <div *ngIf="balkon">
            <h3>Balkon</h3>
            <p style="font-weight: bold;">Auf einem unserer Balkone haben wir den Auslauf von unserer Weibchengruppe
                eingerichtet (ca. 11m&#178;).
                Sie leben zusammen mit einem Kastraten und haben drei grosse Ställe, die als Unterschlupf dienen.
                Natürlich dürfen auch hier die verschiedenen Versteckmöglichkeiten nicht fehlen, damit sich alle so
                richtig wohlfühlen können. Ab und zu gibt es Tannenzweige zum Knabbern, welche unsere Tiere über alles
                lieben.</p>
            <img src="../../assets/images/auslauf/balkon/IMG_4308.JPG">
            <img src="../../assets/images/auslauf/balkon/IMG_4311.JPG">
            <img src="../../assets/images/auslauf/balkon/IMG_4318.JPG">
            <img src="../../assets/images/auslauf/balkon/IMG_4323.JPG">
            <img src="../../assets/images/auslauf/balkon/P9250601.JPG">
            <img src="../../assets/images/auslauf/balkon/P9250608.JPG">
            <img src="../../assets/images/auslauf/balkon/P9250609.JPG">
            <img src="../../assets/images/auslauf/balkon/PA310709.JPG">
        </div>
    </div>
</div>