import { Component, OnInit, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-login',
  templateUrl: './admin.component.login.html',
  styleUrls: ['./admin.component.css']
})
export class LoginComponent implements OnInit {

  model = {
    user: '',
    passwd: ''
  };
  submitted = false;
  sent = false;
  loggedMessage = "";

  constructor(private router: Router, private auth: Auth) { }

  ngOnInit() {
    this.auth.check().then(ret => {
      if (ret.logged) {
        this.router.navigate(['/home']);
      }
    });
  }

  onSubmit(data: any) {
    this.submitted = false;
    this.sent = true;
    let obs = this.auth.login(data);
    obs.subscribe(res => {
      if (res.returnCode == "OK") {
        this.loggedMessage = "";
        window.location.reload();
      } else {
        this.sent = false;
        this.loggedMessage = "Login fehlgeschlagen, bitte erneut versuchen!";
      }
      this.submitted = true;
    });
  }

  cancel() {
    window.history.back();
  }

}

@Component({
  selector: 'app-login',
  templateUrl: './admin.component.logout.html',
  styleUrls: ['./admin.component.css']
})
export class LogoutComponent implements OnInit {

  logged = false;

  constructor(private router: Router, private auth: Auth, private cookieService: CookieService) { }

  ngOnInit() {
    this.auth.check().then(ret => {
      this.logged = ret.logged;
      if (!ret.logged) {
        this.router.navigate(['/home']);
      }
    });
  }

  onSubmit() {
    let obs = this.auth.logout();
    obs.then(res => {
      if (res.returnCode == "OK") {
        this.cookieService.delete('PHPSESSID');
        window.location.reload();
      }
    });
  }

  cancel() {
    window.history.back();
  }

}

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  date = new Date();
  model = {
    date: this.date.toISOString().substring(0, 10),
    title: '',
    text: ''
  };
  newUser = {
    user: '',
    passwd: '',
    repeat: ''
  };
  logged = false;
  news = false;
  meerschweinchen = false;
  account = false;
  add = false;
  edit = false;
  delete = false;
  sent = false;
  success = false;
  data_news;
  params;

  imageChangedEvent: any = '';
  croppedImage: any = '';

  constructor(private auth: Auth, private route: ActivatedRoute, private http: HttpClient, private router: Router) { }

  ngOnInit() {
    this.auth.check().then(ret => {
      this.logged = ret.logged;
      if (!this.logged) {
        this.router.navigate(['/home']);
      }
    });
    const entity = this.route.snapshot.paramMap.get('entity');
    entity == "news" ? this.news = true : this.news = false;
    entity == "meerschweinchen" ? this.meerschweinchen = true : this.meerschweinchen = false;
    entity == "account" ? this.account = true : this.account = false;
    this.route.snapshot.url[0].path == "add" ? this.add = true : this.add = false;
    this.route.snapshot.url[0].path == "edit" ? this.edit = true : this.edit = false;
    this.route.snapshot.url[0].path == "delete" ? this.delete = true : this.delete = false;
    if (this.news && this.delete) {
      this.getNews();
    }
    if (entity != "news" && entity != "meerschweinchen" && entity != "account") {
      this.router.navigate(['/home']);
    }
    this.route.params.subscribe(
      params => {
        if (this.params != undefined && this.params != params) {
          this.ngOnInit();
        }
        this.params = params;
      }
    );
  }

  back() {
    window.history.back();
  }

  addNews(data: any) {
    this.logged = false;
    this.sent = false;
    let body = JSON.stringify({ 'Datum': data.date, 'Titel': data.title, 'Text': data.text });
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=UTF-8'
      })
    };
    const url = "https://grifo.ch/api/add/blog.php";
    const response = this.http.post(url, body, httpOptions);
    response.subscribe(res => {
      this.logged = true;
      this.sent = true;
      if (res['returnCode'] == "OK") {
        this.success = true;
      } else {
        this.success = false;
      }
    });
  }

  deleteNews() {
    this.logged = false;
    this.sent = false;
    let body = JSON.stringify({ 'id': this.route.snapshot.paramMap.get('id') });
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=UTF-8'
      })
    };
    const url = "https://grifo.ch/api/delete/blog.php";
    const response = this.http.post(url, body, httpOptions);
    response.subscribe(res => {
      this.logged = true;
      this.sent = true;
      if (res['returnCode'] == "OK") {
        this.success = true;
      } else {
        this.success = false;
      }
    });
  }

  async getNews() {
    const id = this.route.snapshot.paramMap.get('id');
    const url = "https://grifo.ch/api/get/blog.php?id=" + id;
    const response = await fetch(url);
    this.data_news = await response.json();
  }

  register(data: any) {
    this.logged = false;
    this.sent = false;
    const response = this.auth.register(data);
    response.subscribe(res => {
      this.logged = true;
      this.sent = true;
      if (res['returnCode'] == "OK") {
        this.success = true;
      } else {
        this.success = false;
      }
    });
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

}

@Injectable({
  providedIn: 'root'
})
export class Auth {

  constructor(private http: HttpClient, private cookieService: CookieService) { }

  async check() {
    const ret = {
      logged: false,
      user: ''
    };
    if (this.cookieService.get('PHPSESSID') == '') {
      return ret;
    } else {
      const url = "https://grifo.ch/api/auth/logged.php";
      const response = await fetch(url);
      const json = await response.json();
      if (json.logged == "YES") {
        ret.logged = true;
        ret.user = json.user;
        return ret;
      } else {
        return ret;
      }
    }
  }

  login(data): Observable<any> {
    let body = JSON.stringify({ 'user': data.user, 'passwd': data.passwd });
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=UTF-8'
      })
    };
    const url = "https://grifo.ch/api/auth/login.php";
    return this.http.post(url, body, httpOptions);
  }

  async logout() {
    const url = "https://grifo.ch/api/auth/logout.php";
    const response = await fetch(url);
    const json = await response.json();
    return json;
  }

  register(data): Observable<any> {
    let body = JSON.stringify({ 'user': data.user, 'passwd': data.passwd });
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=UTF-8'
      })
    };
    const url = "https://grifo.ch/api/add/account.php";
    return this.http.post(url, body, httpOptions);
  }

}