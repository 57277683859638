import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { ImageCropperModule } from 'ngx-image-cropper';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { MannschaftComponent, MannschaftDetailComponent } from './mannschaft/mannschaft.component';
import { AbgabeComponent } from './abgabe/abgabe.component';
import { AuslaufComponent } from './auslauf/auslauf.component';
import { LoginComponent, LogoutComponent, AdminComponent } from './admin/admin.component';
import { VerpaarungComponent } from './verpaarung/verpaarung.component';
import { NachwuchsComponent } from './nachwuchs/nachwuchs.component';

const appRoutes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'add/:entity', component: AdminComponent },
  { path: 'edit/:entity/:id', component: AdminComponent },
  { path: 'delete/:entity/:id', component: AdminComponent },
  { path: 'home', component: HomeComponent },
  { path: 'mannschaft', component: MannschaftComponent },
  { path: 'meerschweinchen/:id', component: MannschaftDetailComponent },
  { path: 'nachwuchs', component: NachwuchsComponent },
  { path: 'verpaarung', component: VerpaarungComponent },
  { path: 'abgabe', component: AbgabeComponent },
  { path: 'auslauf', component: AuslaufComponent },
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MannschaftComponent,
    MannschaftDetailComponent,
    AbgabeComponent,
    AuslaufComponent,
    LoginComponent,
    LogoutComponent,
    AdminComponent,
    VerpaarungComponent,
    NachwuchsComponent
  ],
  imports: [
    RouterModule.forRoot(appRoutes, { enableTracing: false }),
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ImageCropperModule
  ],
  providers: [CookieService],
  bootstrap: [AppComponent, HomeComponent, MannschaftComponent, MannschaftDetailComponent, AbgabeComponent, AuslaufComponent]
})
export class AppModule { }
