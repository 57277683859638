<div id="container">
  <div id="header">
    <div id="desktop">
      <a *ngIf="!logged" id="login_button" routerLink="login">Login</a>
      <div id="logout_button">
        <p *ngIf="logged" style="padding: 0 0.5em 0 0; font-weight: bold; margin: 0;">Angemeldet als:</p>
        <p *ngIf="logged" style="padding: 0 0.5em 0 0; margin: 0 0 0.5em 0;">{{user}}</p>
        <a *ngIf="logged" routerLink="/add/meerschweinchen">Neues Meerschweinchen</a>
        <a *ngIf="logged" routerLink="/add/account">Neuer Benutzer</a>
        <a *ngIf="logged" routerLink="logout">Logout</a>
      </div>
      <div class="title">
        <img src="../assets/images/muminek.png">
        <p>KARIN'S MEERSCHWEINCHEN</p>
        <img src="../assets/images/merlin.png">
      </div>
      <div class="menu">
        <ul>
          <li><a routerLink="home" routerLinkActive="active">Home</a></li>
          <li><a routerLink="mannschaft" routerLinkActive="active">Mannschaft</a></li>
          <li><a routerLink="nachwuchs" routerLinkActive="active">Nachwuchs</a></li>
          <li><a routerLink="verpaarung" routerLinkActive="active">Verpaarungen</a></li>
          <li><a routerLink="abgabe" routerLinkActive="active">Abgabe</a></li>
          <li><a routerLink="auslauf" routerLinkActive="active">Auslauf</a></li>
        </ul>
      </div>
    </div>
    <div id="mobile">
      <p id="title">KARIN'S MEERSCHWEINCHEN</p>
      <div id="mobile_menu">
        <div id="logos">
          <a routerLink="home" (click)="closeHamburger()" routerLinkActive="active"><img src="../assets/images/muminek.png"></a>
          <a routerLink="home" (click)="closeHamburger()" routerLinkActive="active"><img src="../assets/images/merlin.png"></a>
        </div>
        <div class="hamburger">
          <a *ngIf="isCollapsed" (click)="openHamburger()">
            <div></div>
            <div></div>
            <div></div>
          </a>
          <a *ngIf="!isCollapsed" (click)="closeHamburger()">
            <p id="closeHamburger">X</p>
          </a>
        </div>
      </div>
      <div id="hamburger_open" *ngIf="!isCollapsed">
        <a routerLink="home" routerLinkActive="active" (click)="closeHamburger()">Home</a>
        <a routerLink="mannschaft" routerLinkActive="active" (click)="closeHamburger()">Mannschaft</a>
        <a routerLink="nachwuchs" routerLinkActive="active" (click)="closeHamburger()">Nachwuchs</a>
        <a routerLink="verpaarung" routerLinkActive="active" (click)="closeHamburger()">Verpaarungen</a>
        <a routerLink="abgabe" routerLinkActive="active" (click)="closeHamburger()">Abgabe</a>
        <a routerLink="auslauf" routerLinkActive="active" (click)="closeHamburger()">Auslauf</a>
        <button *ngIf="!logged" id="login_button" routerLink="login" (click)="closeHamburger()">Login</button>
        <button *ngIf="logged" id="logout_button" routerLink="logout" (click)="closeHamburger()">Logout</button>
      </div>
    </div>
  </div>
  <div id="body">
    <router-outlet></router-outlet>
  </div>
  <div id="footer">

  </div>
</div>