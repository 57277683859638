<div class="admin">
    <div class="login">
        <h3>Logout</h3>
        <div *ngIf="logged" class="submit_button">
            <p>Alles erledigt?</p>
            <button (click)="onSubmit()">Abmelden</button>
            <button (click)="cancel()">Abbrechen</button>
        </div>
        <div *ngIf="!logged" class="wait">
            <div class="app-loading">
                <div class="logo"></div>
                <svg class="spinner" viewBox="25 25 50 50">
                    <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
                </svg>
            </div>
        </div>
    </div>
</div>