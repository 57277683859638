<div class="admin">
    <h3 *ngIf="edit">Bearbeiten</h3>
    <div *ngIf="logged" class="manage">
        <div *ngIf="news">
            <div *ngIf="add">
                <h3>News hinzufügen</h3>
                <form *ngIf="!success" name="form" (ngSubmit)="myForm.form.valid && addNews(myForm.form.value)"
                    #myForm="ngForm" novalidate>
                    <p>Datum:</p>
                    <input type="date" name="date" class="form-control" [(ngModel)]="model.date" #date="ngModel"
                        [ngClass]="{ 'is-invalid': myForm.submitted && date.invalid }" required>
                    <div *ngIf="myForm.submitted && date.invalid" class="invalid_feedback">
                        <div *ngIf="date.errors.required">Datum wird benötigt!</div>
                    </div>
                    <p>Titel:</p>
                    <input type="text" id="title_input" name="title" class="form-control" [(ngModel)]="model.title"
                        #title="ngModel" [ngClass]="{ 'is-invalid': myForm.submitted && title.invalid }" required
                        autocomplete="off">
                    <div *ngIf="myForm.submitted && title.invalid" class="invalid_feedback">
                        <div *ngIf="title.errors.required">Titel wird benötigt!</div>
                    </div>
                    <p>Text:</p>
                    <textarea rows="8" id="text_input" name="text" class="form-control" [(ngModel)]="model.text"
                        #text="ngModel" [ngClass]="{ 'is-invalid': myForm.submitted && text.invalid }"
                        required></textarea>
                    <div *ngIf="myForm.submitted && text.invalid" class="invalid_feedback">
                        <div *ngIf="text.errors.required">Text wird benötigt!</div>
                    </div>
                    <div class="submit_button">
                        <button>Speichern</button>
                        <button (click)="back()" type="button">Abbrechen</button>
                    </div>
                </form>
            </div>
            <div *ngIf="delete" class="delete">
                <h3>News löschen</h3>
                <div class="submit_button" *ngIf="!success">
                    <p style="margin-bottom: 1em; color: black;">Soll der untenstehende Eintrag wirklich gelöscht
                        werden?</p>
                    <button (click)="deleteNews()">Löschen</button>
                    <button (click)="back()">Abbrechen</button>
                    <div *ngIf="data_news != undefined">
                        <div *ngFor="let d of data_news">
                            <div class="news_entry">
                                <div class="news_header">
                                    <p class="news_title">{{d.Titel}}</p>
                                    <div class="news_date">
                                        <p>{{d.Datum}}</p>
                                    </div>
                                </div>
                                <p class="news_text">{{d.Text}}</p>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="data_news == undefined" class="wait">
                        <div class="app-loading">
                            <div class="logo"></div>
                            <svg class="spinner" viewBox="25 25 50 50">
                                <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2"
                                    stroke-miterlimit="10" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="meerschweinchen">
            <div *ngIf="add">
                <h3>Meerschweinchen hinzufügen</h3>
                <form *ngIf="!success">
                    <p>Name:</p>
                    <input type="text">
                    <p>Geschlecht:</p>
                    <select id="geschlecht">
                        <option value="" selected>Bitte auswählen</option>
                        <option value="M">Männlich</option>
                        <option value="W">Weiblich</option>
                    </select>
                    <p>Farbe:</p>
                    <input type="text">
                    <p>Rasse:</p>
                    <input type="text">
                    <p>Geburtstag:</p>
                    <input type="date">
                    <p>Zucht:</p>
                    <input type="text">
                    <p>Bild:</p>
                    <input type="file" (change)="fileChangeEvent($event)" />
                    <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                        [aspectRatio]="3 / 2" format="png" (imageCropped)="imageCropped($event)"
                        (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()"
                        (loadImageFailed)="loadImageFailed()"></image-cropper>

                    <div class="submit_button">
                        <button>Speichern</button>
                        <button (click)="back()" type="button">Abbrechen</button>
                    </div>
                </form>
            </div>
        </div>
        <div *ngIf="account">
            <div *ngIf="add">
                <h3>Benutzer hinzufügen</h3>
                <form *ngIf="!success" name="form" (ngSubmit)="myForm.form.valid && register(myForm.form.value)"
                    #myForm="ngForm" novalidate>

                    <p>Benutzername (E-Mail):</p>
                    <input type="email" name="user" class="form-control" [(ngModel)]="newUser.user" #user="ngModel"
                        [ngClass]="{ 'is-invalid': myForm.submitted && user.invalid }" autocomplete="off" maxlength="25"
                        required email>
                    <div *ngIf="myForm.submitted && user.invalid" class="invalid_feedback">
                        <div *ngIf="user.errors.required">Benutzername wird benötigt!</div>
                        <div *ngIf="user.errors.email">Ungültiger Benutzername (keine E-Mail)!</div>
                    </div>

                    <p>Passwort:</p>
                    <input type="password" name="passwd" class="form-control" [(ngModel)]="newUser.passwd"
                        #passwd="ngModel" [ngClass]="{ 'is-invalid': myForm.submitted && passwd.invalid }"
                        autocomplete="off" maxlength="16"
                        pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$" required>
                    <div *ngIf="myForm.submitted && passwd.invalid" class="invalid_feedback">
                        <div *ngIf="passwd.errors.required">Passwort wird benötigt!</div>
                        <div *ngIf="passwd.errors.pattern">Passwort ungültig! Mindestens 6 Zeichen, 1 Buchstabe, 1
                            Zahl und 1 Spezialzeichen (@$!%*#?&)</div>
                    </div>

                    <p>Passwort wiederholen:</p>
                    <input type="password" name="repeat" class="form-control" [(ngModel)]="newUser.repeat"
                        #repeat="ngModel" [ngClass]="{ 'is-invalid': myForm.submitted && repeat.invalid }"
                        autocomplete="off" maxlength="16" [pattern]="newUser.passwd" required>
                    <div *ngIf="myForm.submitted && repeat.invalid" class="invalid_feedback">
                        <div *ngIf="repeat.errors.required">Passwort-Wiederholung wird benötigt!</div>
                        <div *ngIf="!repeat.errors.required" ng-message="pattern">Wiederholung stimmt nicht
                            überein!</div>
                    </div>

                    <br>
                    <div class="submit_button">
                        <button>Speichern</button>
                        <button (click)="back()" type="button">Abbrechen</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div *ngIf="sent" class="submit_button">
        <p *ngIf="success && news && add">News erfolgreich hinzugefügt!</p>
        <p *ngIf="success && news && delete">Eintrag erfolgreich gelöscht!</p>
        <p *ngIf="success && account && add">Benutzer erfolgreich hinzugefügt!</p>
        <button *ngIf="success" type="button" (click)="back()">OK</button>
        <p *ngIf="!success" class="denied">Fehler! Versuch' es später nochmal.</p>
    </div>
    <div *ngIf="!logged" class="wait">
        <div class="app-loading">
            <div class="logo"></div>
            <svg class="spinner" viewBox="25 25 50 50">
                <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
            </svg>
        </div>
    </div>
</div>