import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nachwuchs',
  templateUrl: './nachwuchs.component.html',
  styleUrls: ['./nachwuchs.component.css']
})
export class NachwuchsComponent implements OnInit {

  months = ["", "Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];
  wuerfe2025 = true;
  wuerfe2024 = false;
  wuerfe2023 = false;
  wuerfe2022 = false;
  wuerfe2021 = false;
  wuerfe2020 = false;
  data_wuerfe2025;
  data_wuerfe2024;
  data_wuerfe2023;
  data_wuerfe2022;
  data_wuerfe2021;
  data_wuerfe2020;
  wuerfe;

  constructor() { }

  ngOnInit() {
    this.getWuerfe();
  }

  async getWuerfe() {
    const url = "https://grifo.ch/api/get/wuerfe.php";
    const response = await fetch(url);
    const json = await response.json();
    this.data_wuerfe2025 = [];
    this.data_wuerfe2024 = [];
    this.data_wuerfe2023 = [];
    this.data_wuerfe2022 = [];
    this.data_wuerfe2021 = [];
    this.data_wuerfe2020 = [];
    for (let j of json) {
      let year = j.Datum.substring(0, 4);
      let month = this.months[parseInt(j.Datum.substring(5, 7))];
      let day = j.Datum.substring(8);
      j.Datum = "" + day + ". " + month + " " + year;
      if (year == "2020") {
        this.data_wuerfe2020.push(j);
      } else if (year == "2021") {
        this.data_wuerfe2021.push(j);
      } else if (year == "2022") {
        this.data_wuerfe2022.push(j);
      } else if (year == "2023") {
        this.data_wuerfe2023.push(j);
      } else if (year == "2024") {
        this.data_wuerfe2024.push(j);
      } else if (year == "2025") {
        this.data_wuerfe2025.push(j);
      }
    }
    if (this.wuerfe2020) {
      this.wuerfe = this.data_wuerfe2020;
    } else if (this.wuerfe2021) {
      this.wuerfe = this.data_wuerfe2021;
    } else if (this.wuerfe2022) {
      this.wuerfe = this.data_wuerfe2022;
    } else if (this.wuerfe2023) {
      this.wuerfe = this.data_wuerfe2023;
    } else if (this.wuerfe2024) {
      this.wuerfe = this.data_wuerfe2024;
    } else if (this.wuerfe2025) {
      this.wuerfe = this.data_wuerfe2025;
    }
  }

  changeContent(link: string) {
    if (link == "wuerfe2020") {
      this.wuerfe2020 = true;
      this.wuerfe2021 = false;
      this.wuerfe2022 = false;
      this.wuerfe2023 = false;
      this.wuerfe2024 = false;
      this.wuerfe2025 = false;
      this.wuerfe = this.data_wuerfe2020;
    } else if (link == "wuerfe2021") {      
      this.wuerfe2020 = false;
      this.wuerfe2021 = true;
      this.wuerfe2022 = false;
      this.wuerfe2023 = false;
      this.wuerfe2024 = false;
      this.wuerfe2025 = false;
      this.wuerfe = this.data_wuerfe2021;
    } else if (link == "wuerfe2022") {      
      this.wuerfe2020 = false;
      this.wuerfe2021 = false;
      this.wuerfe2022 = true;
      this.wuerfe2023 = false;
      this.wuerfe2024 = false;
      this.wuerfe2025 = false;
      this.wuerfe = this.data_wuerfe2022;
    } else if (link == "wuerfe2023") {      
      this.wuerfe2020 = false;
      this.wuerfe2021 = false;
      this.wuerfe2022 = false;
      this.wuerfe2023 = true;
      this.wuerfe2024 = false;
      this.wuerfe2025 = false;
      this.wuerfe = this.data_wuerfe2023;
    } else if (link == "wuerfe2024") {      
      this.wuerfe2020 = false;
      this.wuerfe2021 = false;
      this.wuerfe2022 = false;
      this.wuerfe2023 = false;
      this.wuerfe2024 = true;
      this.wuerfe2025 = false;
      this.wuerfe = this.data_wuerfe2024;
    } else if (link == "wuerfe2025") {      
      this.wuerfe2020 = false;
      this.wuerfe2021 = false;
      this.wuerfe2022 = false;
      this.wuerfe2023 = false;
      this.wuerfe2024 = false;
      this.wuerfe2025 = true;
      this.wuerfe = this.data_wuerfe2025;
    }
  }

  sidebarStyle(link: string) {
    if (link == "wuerfe2020" && this.wuerfe2020 || link == "wuerfe2021" && this.wuerfe2021 || link == "wuerfe2022" && this.wuerfe2022
    || link == "wuerfe2023" && this.wuerfe2023 || link == "wuerfe2024" && this.wuerfe2024 || link == "wuerfe2025" && this.wuerfe2025) {
      return "active";
    }
    return "";
  }

}
